<template>
	<div v-if="hasManageContentPermission" class="hub-page-audit" :class="computedClass">
		<v-card class="elevation-0">
			<QDataTableHeader color="q_blue_1">
				<template #left>
					<div class="d-flex align-center q-display-3 white--text">
						{{ title }}
						<v-btn class="ml-1" dark icon @click="resetAndReload">
							<v-icon>fas fa-redo-alt</v-icon>
						</v-btn>
					</div>
				</template>

				<template #right>
					<v-spacer />
					<v-text-field
						v-model="search"
						label="Search"
						append-icon="icon-q-search"
						dark
						clearable
						dense single-line hide-details
					/>
				</template>
			</QDataTableHeader>

			<div class="data-table-header-info">
				<p class="mb-2">
					<span class="data-table-header-item">Page Title:</span> <strong>{{ pageTitle }}</strong>
				</p>
				<p class="mb-0">
					<span class="data-table-header-item">Page Slug:</span> <strong>{{ pageSlug }}</strong>
				</p>
			</div>

			<HubPageAuditTable
				:items="items"
				:search="search"
				:options="options"
				:loading="dataLoading"
				class="px-2 mt-4"
				@update-options="options = $event"
				@view-page="viewPage"
			/>
		</v-card>

		<HubPagePreviewDialog
			v-if="doShowViewerDialog"
			v-model="doShowViewerDialog"
			:page="currentViewerPage"
			:mode="PAGE_VIEW_MODE.edit"
			@copy-page="copyPage"
			@edit-page="editPage"
		/>

		<HubPageEditorDialog
			v-model="doShowEditorDialog"
			:page="currentEditorPage"
			:mode="hubPagesEditorMode"
			@saved="onHubPageSave"
		/>
	</div>

	<div v-else class="hub-page-audit">
		<v-alert type="error">You don't have permission</v-alert>
	</div>
</template>

<script>
import { debounce } from 'vue-debounce'

import QDataTableHeader from '@/components/datatables/base/QDataTableHeader.vue'
import HubPageAuditTable from './HubPageAuditTable.vue'
import HubPagePreviewDialog from './HubPagePreviewDialog.vue'
import HubPageEditorDialog from './HubPageEditorDialog.vue'

import { getHubPageAudit } from '../api/index'
import { EDITOR_MODE, PAGE_VIEW_MODE, defaultAuditOptions } from '../config/index'

export default {
    components: {
		QDataTableHeader,
		HubPageAuditTable,
		HubPagePreviewDialog,
		HubPageEditorDialog,
	},
	props: {
		pageId: { type: [Number, String] },
		title: { type: String, default: 'Hub Page Audit Table' },
	},
    data () {
        return {
			PAGE_VIEW_MODE,

			search: null,
			dataLoading: false,
            responseData: [],

            options: { ...defaultAuditOptions },

			doShowViewerDialog: false,
			currentViewerPage: null,

			doShowEditorDialog: false,
			hubPagesEditorMode: EDITOR_MODE.new,
			currentEditorPage: null,
        }
    },
	created () {
		this.loadData()
	},
    computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		computedClass () {
			return [
				this.isMobileView && 'hub-page-audit--mobile'
			]
		},
		items () {
            return this.responseData
        },
		pageTitle () {
			if (!this.responseData?.length) { return '' }
			const auditData = this.responseData[0]
			return auditData.hub_page_data.title
		},
		pageSlug () {
			if (!this.responseData?.length) { return '' }
			const auditData = this.responseData[0]
			return auditData.hub_page_data.slug
		},
		hasManageContentPermission () {
			return this.hasPermission(['manage:Content'])
		},
    },
	watch: {
		doShowEditorDialog (v) {
			if (!v) {
				this.currentEditorPage = null
				this.hubPagesEditorMode = EDITOR_MODE.new
			}
		},
		doShowViewerDialog (v) {
			if (!v) {
				this.currentViewerPage = null
			}
		},
		pageId () {
			this.loadData()
		},
	},
	methods: {
		loadData: debounce(async function () {
			if (!this.pageId) { return }
            this.dataLoading = true
            const res = await getHubPageAudit(this.pageId)
			if (res.error) {
				if (res.message === 'Aborted') { return }
				this.responseData = []
				console.log(res.error)
				this.showError('Whoops! There was a problem loading Hub Page Audit data.<br>', res.message)
			} else {
				this.responseData = res.data
				this.dataLoading = false
			}
        }, 200),
		viewPage (pageAuditItem) {
			this.currentViewerPage = { ...pageAuditItem.hub_page_data, id: this.pageId }
			this.doShowViewerDialog = true
		},
		copyPage (page) {
			this.doShowViewerDialog = false
			this.currentEditorPage = { ...page }
			this.hubPagesEditorMode = EDITOR_MODE.copy
			this.doShowEditorDialog = true
		},
		editPage (page) {
			this.doShowViewerDialog = false
			this.currentEditorPage = { ...page }
			this.hubPagesEditorMode = EDITOR_MODE.edit
			this.doShowEditorDialog = true
		},
		onHubPageSave (savedPage) {
			if (savedPage.id !== this.currentEditorPage.id && this.$route.name === 'HubPageAudit') {
				// for copy mode and HubPageAudit route
				this.$router.push({ params: { pageId : savedPage.id } })
			} else {
				this.loadData()
			}
			this.doShowEditorDialog = false
		},
		resetAndReload () {
            this.search = null
			this.options = {
				...this.options,
				...defaultAuditOptions,
				itemsPerPage: this.options.itemsPerPage,
			}
			this.loadData()
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

.hub-page-audit {
	--padding: 1rem;

	&.hub-page-audit--mobile {
		--padding: 0.25rem;
	}

	padding: var(--padding);

	.data-table-header-info {
		padding: 0.5rem 0.5rem 1rem;
		font-size: 1rem;
	}

	.data-table-header-item {
		font-size: 0.875rem;
		opacity: 0.8;
	}
}
</style>
